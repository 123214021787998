<template>
  <div v-if="show" class="manage_trading_wrapper">
    <el-form :model="form" :rules="rules" ref="form">
      <tw-header v-if="mode === 'edit'">
        <template v-slot:title>
          <el-form-item class="trading_name" label="Trading Name" prop="newTradingName">
            <el-input v-model="form.newTradingName" placeholder="Trading Name" show-word-limit maxlength="100" />
          </el-form-item>
        </template>
      </tw-header>

      <tw-header v-else>
        <template v-if="tradingName" v-slot:title>
          <div>
            <h1 class="title" :class="{long: tradingName.length > 75, middle: tradingName.length > 50}">{{tradingName}}</h1>
            <div style="font-size: 14px;line-height: 20px;margin-top: 4px;">{{tradingId}}</div>
          </div>
          <tw-status-label v-if="isDone" :statusName="'DONE'" size="large" style="margin-left: 12px" />
        </template>
        <template v-if="tradingName" v-slot:column>
          <tw-customer prefix="owner" :customer="ownerInfo" style="margin-left: 24px;" />

          <el-dropdown class="header_dropdown" trigger="click" placement="bottom-start" :tabindex="-1">
            <div class="el-dropdown-link">
              <i class="chevron" :class="{open: showDropdown}" @click="showDropdown = !showDropdown" />
            </div>
            <el-dropdown-menu class="header_dropdown users">
              <el-dropdown-item>
                <div class="user">
                  <figure class="avatar table"><img :src="tradingInfo.tradingCreateUserIcon"></figure>
                  <div class="from_name">{{tradingInfo.tradingCreateUserName}}</div>
                </div>
                Registered Date<span style="margin-right:8px" />{{tradingInfo.tradingCreateDate | dateTimeFormat}}
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="user">
                  <figure class="avatar table"><img :src="tradingInfo.tradingUpdateUserIcon"></figure>
                  <div class="from_name">{{tradingInfo.tradingUpdateUserName}}</div>
                </div>
                Update Date<span style="margin-right:8px" />{{tradingInfo.tradingUpdateDate | dateTimeFormat}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i class="url_copy" @click="urlCopy" />
        </template>
      </tw-header>

      <div class="tw_container" ref="container">
        <div class="flex" ref="">
          <tw-breadcrumbs v-if="breadcrumbs.length && !mode" :pages="breadcrumbs" />
          <div v-if="mode" class="back" style="margin-bottom: 0" @click="back">
            <img src="@/assets/images/icons/chevron_left_gray800.svg">Back
          </div>
          <div v-if="mode" class="buttons">
            <tw-button type="default" size="medium" @click="cancel">Cancel</tw-button>
            <tw-button v-if="mode !== 'editFlow'" type="secondary" size="medium" icon="plus" :disabled="!copyable" @click="copyFlow">Copy</tw-button>
            <tw-button v-if="mode !== 'editFlow'" type="secondary" size="medium" icon="plus" :disabled="!canAddNewFlow" @click="addFlow">New</tw-button>
            <tw-button v-if="mode !== 'editFlow'" type="secondary" size="medium" :disabled="!canPartial" @click="showPartial(currentFlow.tradingFlowId)">Partial</tw-button>
            <tw-button v-if="mode !== 'editFlow'" type="secondary" size="medium" :disabled="!canCombine" @click="showCombine(currentFlow.tradingFlowId)">Combine</tw-button>
            <tw-button type="primary" size="medium" :disabled="!needRegisterButton" @click="register">Register</tw-button>
          </div>
          <div v-else-if="isShipper" class="buttons">
            <tw-button v-if="editable" type="secondary" size="medium" :disabled="!canPartial" @click="showPartial(currentFlow.tradingFlowId)">Partial</tw-button>
            <tw-button v-if="editable" type="secondary" size="medium" :disabled="!canCombine" @click="showCombine(currentFlow.tradingFlowId)">Combine</tw-button>
            <tw-button v-if="editable" type="primary" size="medium" :disabled="!canEditFlow" @click="edit(currentFlow)">Edit Flow</tw-button>
            <tw-button v-if="editable" type="primary" size="medium" @click="edit">Edit All</tw-button>
          </div>
        </div>
        <div v-if="mode && tradingInfo && tradingInfo.isOwnerFlg === IS_OWNER_FLG.ON" class="sub_buttons">
          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu" :disabled="!(currentFlow && currentFlow.entityStatus.length) || !tradingInfo.tradingPatternInfo">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="viewPattern">View Pattern</el-dropdown-item>
              <el-dropdown-item command="savePattern">Save Pattern</el-dropdown-item>
              <el-dropdown-item command="setSection">Set Section</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div ref="pos" />

        <tw-table-system v-if="mode" ref="table" :expand="true" :schemas="schemas" :processSchemas="processSchemas" :items="form.items" :pageSize="pageSize" :selection="selection" :nopager="true" :mode="mode" :height="maxHeight" :class="['new', {validation: shouldValidation, disabled: tradingInfo.editableFlg === EDITABLE_FLG.OFF, edit_flow: mode === 'editFlow'}]" :rowKey="getRowKey" :expandRowKeys="expandRowKeys" :flowNameRule="flowNameRule" :editableTradingFlowIds="editableTradingFlowIds" :isPureTextFlowName="!isShipper" @process-row-click="rowClick" @process-row-dbclick="rowDbClick" @remove-flow="removeFlow" @dirty="isDirty = true" :highlight-current-row="mode === 'editFlow' || !!tradingInfo.tradingPatternInfo" @current-change="handleCurrentChange" @expand-key-state-system="updateExpand" />

        <tw-table-system v-else ref="table" :expand="true" :schemas="manageSchemas" :processSchemas="manageProcessSchemas" :items="form.items" :pageSize="pageSize" :selection="selection" :nopager="true" :mode="mode" :height="maxHeight" :tradingId="tradingId" :flowNameRule="flowNameRule" :isPureTextFlowName="!isShipper" @process-row-click="rowClick" @process-row-dbclick="rowDbClick" @remove-flow="removeFlow" @show-flow-detail="showFlowDetail" @other-trading-flow-click="showOtherTrading" :rowKey="getRowKey" :expandRowKeys="expandRowKeys" :highlight-current-row="true" @current-change="handleCurrentChange" @expand-key-state-system="updateExpand" />
      </div>
    </el-form>
    <tw-drawer>
      <template slot>
        <tw-trading-detail-inner v-if="drawerShow" :tradingId="selectTradingId" :tradingFlowId="selectFlowId" />
      </template>
    </tw-drawer>

    <!-- 分納モード -->
    <el-drawer :visible.sync="isShowPartial" :title="$t('Label.Partial')" size="100%" :before-close="closePartial">
      <tw-partial-view v-if="isShowPartial" ref="partial" :tradingId="tradingId" :tradingFlowId="selectPartialFlowId" :tradingName="tradingName" :editable="editable" :mode="mode" @partial-registered="onPartialRegistered" @cancel="closePartial" @show-flow-detail="showFlowDetail" />
    </el-drawer>

    <!-- 混載モード -->
    <el-drawer :visible.sync="isShowCombine" :title="$t('Label.Combine')" size="100%" :before-close="closePartial">
      <TwCombineView v-if="isShowCombine" ref="combine" :tradingId="tradingId" :tradingFlowId="selectCombineFlowId" :tradingName="tradingName" :editable="editable" :mode="mode" @partial-registered="onCombineRegistered" @cancel="closeCombine" @show-flow-detail="showFlowDetail" />
    </el-drawer>

    <!-- View Pattern -->
    <el-dialog :visible.sync="isShowViewPattern" width="1240px" :close-on-click-modal="false" :append-to-body="true" class="view_pattern_dialog">
      <TwViewPattern v-if="isShowViewPattern" :tradingInfo="tradingInfo" @close="toggleViewPattern" />
    </el-dialog>
    <!-- Save Pattern -->
    <el-drawer :visible.sync="isShowSavePattern" size="100%" :modal="false" :withHeader="false" class="save_pattern_drawer">
      <TwSavePattern v-if="isShowSavePattern" :items="form.items" :tradingInfo="tradingInfo" :currentFlow="currentFlow" @close="toggleSavePattern" />
    </el-drawer>
    <!-- Set Section -->
    <el-dialog :title="$t('Label.Set Section')" :visible.sync="isShowSetSection" width="617px" :show-close="false" :close-on-click-modal="false" :append-to-body="true" class="set_section_dialog header_border">
      <TwSetSection v-if="isShowSetSection" :tradingInfo="tradingInfo" :currentFlow="currentFlow" @close="toggleSetSection" @register="assignSection" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { EDITABLE_FLG, SECTION_PREFIX, TRADING_PROGRESS_STATUS, DELETE_ABLE_FLG, COPYABLE_FLG, DETAIL_VIEWABLE_FLG, PROCESS_CANCEL_FLG, TRADING_REGISTRATION_STATUS, COMPANY_ROLE_CD, IS_OWNER_FLG, VISIBLE_FLG, ABLE_FLG, OTHER_TRADING_FLG } from 'lib-tw-common';
import { orderProcesses } from '@/utils/entity.js';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinValidation from '@/utils/mixinValidation.js';
import { processNames } from '@/dictionaries/map.js';
import { generateUuid, orderEntities } from '@/utils/searchUtil.js';
import TwStatusLabel from '@/components/atoms/TwStatusLabel';
import TwCustomer from '@/components/molecules/TwCustomer';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwTradingDetailInner from '@/components/templates/TwTradingDetailInner';
import TwPartialView from '@/components/templates/TwPartialView';
import TwCombineView from '@/components/templates/TwCombineView';
import schemas from '@/dictionaries/newTradingListSchema.json';
import processSchemas from '@/dictionaries/manageTradingListEditProcessSchema.json';
import manageSchemas from '@/dictionaries/manageTradingListSchema.json';
import manageProcessSchemas from '@/dictionaries/manageTradingListProcessSchema.json';
import TwViewPattern from '@/components/organisms/TwViewPattern';
import TwSavePattern from '@/components/organisms/TwSavePattern';
import TwSetSection from '@/components/organisms/TwSetSection';

export default {
  name: 'ManageTrading',
  mixins: [mixinEntityList, mixinValidation],
  props: {
    tradingId: String,
  },
  components: {
    TwStatusLabel,
    TwCustomer,
    TwDrawer,
    TwTradingDetailInner,
    TwPartialView,
    TwCombineView,
    TwViewPattern,
    TwSavePattern,
    TwSetSection,
  },
  provide() {
    return {
      addProcess: this.addProcess,
      setProcess: this.setProcess,
      removeProcess: this.removeProcess,
      getPairFlows: this.getPairFlows,
      setProcessUserMemo: this.setProcessUserMemo,
      s: this,
    };
  },
  data() {
    return {
      schemas: schemas,
      processSchemas: processSchemas,
      manageSchemas: manageSchemas,
      manageProcessSchemas: manageProcessSchemas,
      mode: null,
      tradingInfo: null,
      processList: [],
      form: {
        newTradingName: this.tradingName,
        items: null,
      },
      rules: {
        newTradingName: [
          {
            lakeelMessageSourceFlg: true,
            validationId: 'tw-isMaxLength100',
            validator: this.onEventValidation, trigger: 'blur'
          }
        ],
      },
      flowNameRule: [
        {
          lakeelMessageSourceFlg: true,
          validationId: 'tw-isMaxLength100',
          validator: this.onEventValidation, trigger: 'blur'
        }
      ],
      show: false,
      showDropdown: false,
      deleteTradingFlowIds: [],
      originalItems: [],
      tmpTradingFlowList: [],
      shouldValidation: false, // バリデーション表示の可否
      selectFlowId: null,
      ownerSectionId: null,
      editable: false,
      ownerInfo: {},
      EDITABLE_FLG,
      IS_OWNER_FLG,
      isShowPartial: false,
      isShowCombine: false,
      selectPartialFlowId: null,
      selectCombineFlowId: null,
      isDirty: false, // 操作したかフラグ
      maxHeight: window.innerHeight - 200,
      currentFlow: null,
      editableTradingFlowIds: [], // editFlow時に編集可能なフローのtradingFlowId
      isShowViewPattern: false,
      isShowSavePattern: false,
      isShowSetSection: false,
      tmpChannelFlowNo: 0,
      selectTradingId: null, // 取引詳細ドロワー表示用取引ID
      expandRowKeys: [],
    };
  },
  computed: {
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    tradingName() {
      return _.get(this.tradingInfo, 'tradingName');
    },
    isDone() {
      if (!this.form.items) {
        return false;
      }

      return _.every(this.tradingInfo.tradingFlowList, {tradingProgressStatusCd: TRADING_PROGRESS_STATUS.CLOSED});
    },
    needRegisterButton() {
      return this.form.items && !!this.form.items.length && this.tradingInfo.tradingPatternInfo;//_.get(this.tradingInfo, 'tradingPatternInfo.approveStatus') === APPROVE_STATUS.APPROVAL;
    },
    isShipper() {
      // カンパニーロールが荷主か
      return this.$store.getters.isShipper;
    },
    isFwd() {
      return this.$store.getters.isFwd;
    },
    breadcrumbs() {
      if (this.isShipper || this.isFwd) {
        return [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.Trading List'), name: this.$store.state.tradingListMode }, { label: this.tradingName }];
      } else {
        return [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.Trading List') }, { label: this.tradingName }]
      }
    },
    canAddNewFlow() {
      return this.tradingInfo.tradingPatternInfo && this.tradingInfo.canAddNewFlowFlg === ABLE_FLG.TRUE;
    },
    copyable() {
      return this.tradingInfo.tradingPatternInfo && this.currentFlow && this.currentFlow.copyableFlg === COPYABLE_FLG.ON;
    },
    canPartial() {
      return !this.isDirty && _.get(this.currentFlow, 'subordinateInfo.canPartialShipmentFlg');
    },
    canCombine() {
      return !this.isDirty && _.get(this.currentFlow, 'subordinateInfo.canCombineFlg');
    },
    canEditFlow() {
      return _.get(this.currentFlow, 'editableFlg') === EDITABLE_FLG.ON;
    },
  },
  watch: {
    companyId() {
      this.fetch();
    }
  },
  created() {
    if (!this.tradingPatternId && !this.tradingId) {
      this.$router.replace({ name: 'Page404' });
      return;
    }
    this.show = true;

    this.$store.commit('SET_DRAWER', false);

    if (this.companyId) {
      this.fetch();
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    edit(currentFlow) {
      this.originalItems = _.cloneDeep(this.form.items);
      this.fetchTmpInfo();
      this.form.newTradingName = this.tradingName;
      if (currentFlow) {
        this.mode = 'editFlow';
        this.editableTradingFlowIds = _.map(this.getPairFlows(currentFlow), flow => flow.tradingFlowId);
      } else {
        this.resetCurrentFlow();
        this.mode = 'edit';
      }

      this.shouldValidation = false;

    },
    urlCopy() {
      this.$copyText(location.href).then(() => {
        this.$message({
          message: 'Copied!',
          type: 'success'
        });
      }, () => {});
    },
    back() {
      this.$store
      .dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        if (this.history) {
          this.$router.replace({ name: this.history.name, params: { history: this.history } });
        } else if (window.history.length > 2) {
          this.$router.go(-1);
        } else {
          this.$router.push('/');
        }
      })
      .catch(() => {});
    },
    fetch() {
      const params = {
      // bff_td_6 取引管理初期表示BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/manages/search',
          query: {
            tradingId: this.tradingId
          }
        }
      };

      $api.request(params)
      .then(res => {
        if (res.tradingInfo.tradingSubmitStatusCd === TRADING_REGISTRATION_STATUS.SAVING) {
          this.$router.push({
            name: 'SavedTrading',
            params: { tradingId: this.tradingId },
          });
          return;
        }
        if (_.get(this.$route, 'query.flowId')) {
          this.selectFlowId = _.get(this.$route, 'query.flowId');
          this.$store.commit('SET_DRAWER', true);
        }
        this.tradingInfo = res.tradingInfo;
        this.ownerInfo = res.tradingInfo;
        this.ownerSectionId = res.tradingInfo.ownerSectionId;
        this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
        this.originalItems = _.cloneDeep(this.form.items);
        this.editable = this.tradingInfo.editableFlg === EDITABLE_FLG.ON;
      })
      .catch(err => {
        this.form.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    fetchTmpInfo() {
      const params = {
        // bff_td_4 取引管理新規登録初期表示BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/{companyId}/manages/editor',
          path: {
            companyId: this.companyId,
          },
          query: {
            tradingPatternId: this.tradingInfo.tradingPatternId,
            tradingId: this.tradingId,
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          this.tradingInfo = res.tradingInfo;
          this.tmpTradingFlowList = res.tradingInfo.tradingPatternInfo.tmpTradingFlowList;
          this.processList = res.processList;
          if (this.mode === 'editFlow') {
            this.form.items = _.map(this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo'), flow => {
              return {
                ...flow,
                currentFlow: this.editableTradingFlowIds.includes(flow.tradingFlowId)
              }
            });
          } else {
            this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
          }

          this.isDirty = false;
        })
        .catch(err => {
          this.form.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return { ..._.omit(o, [target]), ...o.linkageInfo };
      });
    },
    // プロセス詳細に遷移します
    rowClick(row) {
      if (this.mode) {
        return;
      }
      if (row.detailViewableFlg === DETAIL_VIEWABLE_FLG.OFF) {
        return;
      }
      if (!row.fromSectionId || !row.toSectionId) {
        return;
      }
      this.$router.push({
        name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() },
      });
    },
    // プロセス詳細を別タブで開きます
    rowDbClick(row) {
      if (this.mode) {
        return;
      }
      if (!row.fromSectionId || !row.toSectionId) {
        return;
      }
      this.linkToOtherWindow({
        name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() }
      });
    },
    /**
     * 新規プロセスを返却します
     * @param { Object }  p プロセスモデル
     * @param { Boolean } plane 中身を空にするか
     */
    createNewProcess(p, plane) {
      return {
        processId: p.tmpProcessId,
        processType: p.tmpProcessType,
        processName: p.tmpProcessName,
        processUserMemo: p.tmpProcessUserMemo,
        fromCompanyRoleCd: parseInt(p.tmpFromCompanyRoleCd),
        fromCustomerId: plane ? '' : p.tmpFromCustomerId,
        fromCompanyId: plane ? '' : p.tmpFromCompanyId,
        fromCompanyName: plane ? '' : p.tmpFromCompanyName,
        fromCompanyShortName: plane ? '' : p.tmpFromCompanyShortName,
        fromSectionId: plane ? '' : p.tmpFromSectionId,
        fromSectionName: plane ? '' : p.tmpFromSectionName,
        fromSectionShortName: plane ? '' : p.tmpFromSectionShortName,
        toCompanyRoleCd: parseInt(p.tmpToCompanyRoleCd),
        toCustomerId: plane ? '' : p.tmpToCustomerId,
        toCompanyId: plane ? '' : p.tmpToCompanyId,
        toCompanyName: plane ? '' : p.tmpToCompanyName,
        toCompanyShortName: plane ? '' : p.tmpToCompanyShortName,
        toSectionId: plane ? '' : p.tmpToSectionId,
        toSectionName: plane ? '' : p.tmpToSectionName,
        toSectionShortName: plane ? '' : p.tmpToSectionShortName,
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
      };
    },
    // 新しいフローを返却します
    createNewFlow(tmpFlow) {
      const g = _.groupBy(tmpFlow.tmpProcessList, 'tmpEntityType');
      const entityStatus = _.map(g, ary => {
        return {
          entityTypeCd: ary[0].tmpEntityType,
          processStatus: _.map(ary, p => {
            return this.createNewProcess(p);
          })
        };
      });
      return {
        tradingManageName: '',
        flowUuid: generateUuid(),
        entityStatus: orderEntities(entityStatus),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
        visibleFlg: VISIBLE_FLG.ON,
        unAddableArea: tmpFlow.unAddableArea || [],
        tradingChannelInfo: {
          flowType: tmpFlow.tmpFlowType,
          channelFlowNo: this.tmpChannelFlowNo,
          deleteChannel: undefined, // Ph6時点要件なし。現時点未使用
        }
      };
    },
    // 新しいフローの配列を返却します
    createNewFlows() {
      this.tmpChannelFlowNo++;
      return _.map(this.tmpTradingFlowList, tmpFlow => {
        return this.createNewFlow(tmpFlow);
      });
    },
    // プロセスを追加します
    addProcess({ entityTypeCd, processId, processType, flowUuid, tradingFlowId }) {
      const flowIndex = _.findIndex(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === tradingFlowId) || (item.flowUuid && item.flowUuid === flowUuid);
      });
      const flow = this.form.items[flowIndex];
      const newProcess = {
        processType: processType,
        processId: processId,
        processName: _.get(_.find(processNames, {processType: processType}), 'nameEn'),
        processUserMemo: '',
        fromCompanyRoleCd: '',
        fromCustomerId: '',
        fromCompanyId: '',
        fromSectionId: '',
        fromSectionName: '',
        toCompanyRoleCd: '',
        toCustomerId: '',
        toCompanyId: '',
        toSectionId: '',
        toSectionName: '',
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
      };

      const entityStatus = _.find(flow.entityStatus, { entityTypeCd: entityTypeCd });
      if (entityStatus) {
        entityStatus.processStatus.push(newProcess);
      } else {
        flow.entityStatus.push({
          entityTypeCd: entityTypeCd,
          processStatus: [newProcess],
        })
      }
      const ordered = orderProcesses(orderEntities(flow.entityStatus));
      flow.entityStatus.splice(0, flow.entityStatus.length);
      flow.entityStatus.push(...ordered);
      this.onResize();
      this.isDirty = true;
    },
    // プロセスを更新します
    setProcess(o, p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const process = _.find(_.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd }).processStatus, o => {
        return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      if (o.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX)) {
        process[`${p.fromTo}CustomerId`] = o.tradingId;
        process[`${p.fromTo}CompanyId`] = null;
      } else {
        process[`${p.fromTo}CompanyId`] = o.tradingId;
        process[`${p.fromTo}CustomerId`] = null;
      }
      process[`${p.fromTo}CompanyShortName`] = o.tradingShortName;
      process[`${p.fromTo}CompanyName`] = o.tradingName;
      process[`${p.fromTo}SectionId`] = o.sectionId;
      process[`${p.fromTo}SectionName`] = o.sectionName;
      process[`${p.fromTo}SectionShortName`] = o.sectionShortName;
      this.isDirty = true;
    },
    // PB-271 
    // TwTable2.vue にて、プロセスメモの入力フォームに値が入力されるたびにイベントを
    // 受け取り、form の内容を変更する。
    setProcessUserMemo(p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const process = _.find(_.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd }).processStatus, o => {
        return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      process.processUserMemo = p.processUserMemo;
      this.isDirty = true;
    },
    // プロセスを削除します
    removeProcess(p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const entity = _.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd });

      const flowType = _.get(flow, 'tradingChannelInfo.flowType');

      // 三国間取引のフローかつ、フロー内のエンティティが1つで、かつそのエンティティのプロセスが1つの場合、フローを削除するかどうかを確認します
      if (flowType && flow.entityStatus.length === 1 && entity.processStatus.length === 1) {
        this.removeFlow(flow);
      } else {
        const index = _.findIndex(entity.processStatus, o => {
          return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
        });
        if (!flow.deleteProcessIds) {
          flow.deleteProcessIds = [];
        }

        flow.deleteProcessIds.push({
          entityId: p.entityId,
          deleteProcessId: p.processId,
          deleteProcessSeq: p.processSeq
        });
        entity.processStatus.splice(index, 1);
        if (_.isEmpty(entity.processStatus)) {
          flow.entityStatus.splice(_.findIndex(flow.entityStatus, { entityTypeCd: p.entityTypeCd }), 1);
        }
        if (_.isEmpty(flow.entityStatus)) {
          const tradingFlowId = flow.tradingFlowId;
          this.form.items.splice(_.findIndex(this.form.items, flow), 1);
          if (tradingFlowId) {
            this.deleteTradingFlowIds.push(tradingFlowId);
          }
        }
        this.isDirty = true;
        this.onResize();
      }


    },
    // フローを追加します
    addFlow() {
      const newFlows = this.createNewFlows();
      this.form.items = this.form.items.concat(newFlows);
      this.expandRowKeys = this.expandRowKeys.concat(newFlows.map(flow => flow.flowUuid));
      this.isDirty = true;
    },
    // フローを削除します
    removeFlow(row) {
      let message = 'Are you sure you want to delete this data?';
      const flowType = _.get(row, 'tradingChannelInfo.flowType');
      if (flowType) {
        message = 'Offshore trading is selected. Are you sure you want to delete the set of paired export and import flows?';
      }
      this.$store
        .dispatch('SHOW_CONFIRM', message)
        .then(() => {
          const flows = this.getPairFlows(row);
          flows.forEach(flow => {
            const index = _.findIndex(this.form.items, item => {
              return (item.tradingFlowId && item.tradingFlowId === flow.tradingFlowId) || (item.flowUuid && item.flowUuid === flow.flowUuid);
            });
            this.form.items.splice(index, 1);
            if (flow.tradingFlowId) {
              this.deleteTradingFlowIds.push(flow.tradingFlowId);
            }
          });

          this.isDirty = true;
          this.resetCurrentFlow();
        })
        .catch(() => {});
    },
    // 変更をキャンセルします
    cancel() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          this.form.items = this.originalItems;
          this.mode = null;
          this.isDirty = false;
          this.resetCurrentFlow();
          this.tradingInfo.tradingPatternInfo = undefined;
          this.deleteTradingFlowIds = [];
        })
        .catch(() => {});
    },
    // 指定されていないセクションがあるかを返却します
    hasEmptySections() {
      return !!this.$el.querySelector('.invalid');
    },
    // 削除したプロセスがあればコンファームを出します
    async confirmDeleteProcess() {
      const count = _.reduce(this.form.items, (sum, flow) => {
        sum += _.size(flow.deleteProcessIds);
        return sum;
      }, 0);
      if (count === 0) {
        return true;
      } else {
        return await this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to preceed? Deleted data will be permanently deleted.')
          .then(() => true)
          .catch(() => false);
      }
    },
    // 取引を登録します
    async register() {
      this.shouldValidation = true;
      if (this.hasEmptySections()) {
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        return;
      }
      if (await this.confirmDeleteProcess() === false) {
        return;
      }
      this.$store.commit('START_PROCESS');
      if (await this.validationCheck('form')) {
        const tradingFlowList = _.map(this.form.items, flow => {
          const entityStatus = _.map(flow.entityStatus, s => {
            return {
              ...s,
              provisionalId: s.entityId ? undefined : generateUuid(20),
            };
          });
          return {
            ...flow,
            entityStatus: entityStatus,
          }
        });
        const data = {
          tradingInfo: {
            tradingId: this.tradingId,
            tradingCreateUserId: this.tradingInfo.tradingCreateUserId,
            tradingCreateUserName: this.tradingInfo.tradingCreateUserName,
            tradingCreateUserIcon: this.tradingInfo.tradingCreateUserIcon,
            tradingCreateDate: this.tradingInfo.tradingCreateDate,
            tradingUpdateUserId: this.tradingInfo.tradingUpdateUserId,
            tradingUpdateDate: this.tradingInfo.tradingUpdateDate,
            deleteTradingFlowIds: this.deleteTradingFlowIds,
            tradingName: this.form.newTradingName,
            tradingPatternInfo: this.tradingInfo.tradingPatternInfo,
            tradingFlowList: tradingFlowList
          }
        };
        
        // EditFlow時だけ選択されたフローIDと関連しているフローIDを追加
        if(this.mode === 'editFlow') {
          data.tradingInfo.selectTradingFlowIds = this.editableTradingFlowIds.concat(this.deleteTradingFlowIds.filter(id => !this.editableTradingFlowIds.includes(id)));
          data.tradingInfo.tradingFlowList = tradingFlowList.filter(flow => this.editableTradingFlowIds.includes(flow.tradingFlowId) && flow.otherTradingFlg !== 1);
        }
        
        const params = {
          // bff_td_10 取引管理新規登録BFF
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/tradings/{companyId}/manages',
            path: {
              companyId: this.companyId
            }
          },
          data: data
        };
        
        $api
          .request(params)
          .then(res => {
            if (!_.isArray(res.tradingInfo.tradingFlowList)) {
              res.tradingInfo.tradingFlowList = [res.tradingInfo.tradingFlowList];
            }
            this.shouldValidation = false;
            this.deleteTradingFlowIds = [];
            this.tradingInfo = res.tradingInfo;
            this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
            this.originalItems = _.cloneDeep(this.form.items);
            this.$store.commit('END_PROCESS');
            this.mode = null;
            this.isDirty = false;
            this.resetCurrentFlow();
            // this.$router.push({name: 'ListTrading'});

            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    showFlowDetail(row) {
      this.selectTradingId = row.otherTradingId || row.tradingId || this.tradingId;
      this.selectFlowId = row.tradingFlowId;
      this.$store.commit('SET_DRAWER', true);
    },
    // 分納管理画面を表示します
    showPartial(tradingFlowId) {
      this.selectPartialFlowId = tradingFlowId;
      this.isShowPartial = true;
    },
    // 分納管理画面を閉じます
    closePartial() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          this.resetCurrentFlow();
          this.isShowPartial = false;
        })
        .catch(() => {});
    },
    // 分納情報を取引管理編集画面に反映します
    onPartialRegistered(res) {
      this.mode = 'edit';
      this.resetCurrentFlow();
      this.tradingInfo = res.tradingInfo;
      this.tmpTradingFlowList = res.tradingInfo.tradingPatternInfo.tmpTradingFlowList;
      this.processList = res.processList;
      this.form.newTradingName = this.tradingName;
      this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
      this.originalItems = _.cloneDeep(this.form.items);
      this.isShowPartial = false;
    },
    // 混載管理画面を表示します
    showCombine(tradingFlowId) {
      this.selectCombineFlowId = tradingFlowId;
      this.isShowCombine = true;
    },
    // 混載管理画面を閉じます
    closeCombine() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          this.resetCurrentFlow();
          this.isShowCombine = false;
        })
        .catch(() => {});
    },
    // 混載情報を取引管理編集画面に反映します
    onCombineRegistered(res) {
      this.mode = 'edit';
      this.resetCurrentFlow();
      this.tradingInfo = res.tradingInfo;
      this.tmpTradingFlowList = res.tradingInfo.tradingPatternInfo.tmpTradingFlowList;
      this.processList = res.processList;
      this.form.newTradingName = this.tradingName;
      this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
      this.originalItems = _.cloneDeep(this.form.items);
      this.isShowCombine = false;
    },
    // テーブルのrow-keyを返却します（アコーディオン開閉状態保持）
    getRowKey(row) {
      return row.flowUuid ? row.flowUuid : row.otherTradingId ? row.otherTradingId + '_' + row.tradingFlowId : row.tradingFlowId;
    },
    onResize() {
      const maxHeight = this.$refs.container.clientHeight - this.$refs.pos.offsetTop - 24;
      if (this.maxHeight === maxHeight) {
        this.maxHeight = this.maxHeight - 1;
        this.$nextTick(() => {
          this.maxHeight = maxHeight;
        });
      } else {
        this.maxHeight = maxHeight;
      }
    },
    // フローの選択を解除します
    resetCurrentFlow() {
      this.currentFlow = null;
      this.editableTradingFlowIds = [];
      this.$refs.table.setCurrentRow();
    },
    handleCurrentChange(val, oldVal) {
      //フローIDがnullまたはundefinedの場合にフローの選択を解除させる
      if (!val || !val.tradingFlowId) {
        this.$refs.table.setCurrentRow(null);
        return;
      }
      // 別取引の場合は選択させない
      if (val && val.otherTradingFlg === OTHER_TRADING_FLG.ON) {
        this.currentFlow = null;
        this.$refs.table.setCurrentRow();
        return;
      }
      // Edit Flow時は変更不可
      if (this.mode === 'editFlow') {
        if (this.currentFlow.tradingFlowId === val.tradingFlowId) {
          return;
        } else if (this.editableTradingFlowIds.includes(val.tradingFlowId)) {
          this.currentFlow = val;
          return;
        }
        this.$refs.table.setCurrentRow(oldVal);
        return;
      }
      // Editモードに切り替え中は選択させない
      if (this.mode && !this.tradingInfo.tradingPatternInfo) {
        this.currentFlow = null;
        this.$refs.table.setCurrentRow();
        return;
      }

      this.currentFlow = val;
    },
    /**
     * プロセスをコピーします
     * @param { Object }  p プロセスモデル
     */
    copyProcess(p) {
      const keys = ['processId', 'processType', 'processName', 'fromCompanyRoleCd', 'fromCustomerId', 'fromCompanyId', 'fromCompanyName', 'fromCompanyShortName', 'fromSectionId', 'fromSectionName', 'fromSectionShortName', 'toCompanyRoleCd', 'toCustomerId', 'toCompanyId', 'toCompanyName', 'toCompanyShortName', 'toSectionId', 'toSectionName', 'toSectionShortName', 'uuid', 'editableFlg', 'deletableFlg'];
      return {
        ..._.pick(p, keys),
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
      };
    },
    // 選択したフローのコピーを返却します
    createCopyFlow(flow) {
      const entityStatus = _.map(flow.entityStatus, entity => {
        return {
          entityTypeCd: entity.entityTypeCd,
          processStatus: _.map(_.reject(entity.processStatus, {eventCancel: PROCESS_CANCEL_FLG.ON}), p => {
            return this.copyProcess(p);
          })
        };
      });
      const tmpFlow = this.getTmpFlow(flow);
      const newFlow = {
        tradingManageName: '',
        flowUuid: generateUuid(),
        entityStatus: entityStatus,
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
        visibleFlg: VISIBLE_FLG.ON,
        unAddableArea: _.get(tmpFlow, 'unAddableArea', []),
        tradingChannelInfo: {
          flowType: tmpFlow.tmpFlowType,
          channelFlowNo: this.tmpChannelFlowNo,
          deleteChannel: undefined, // Ph6時点要件なし。現時点未使用
        },
        copyTradingFlowId: flow.tradingFlowId || flow.copyTradingFlowId,
      };

      return newFlow;
    },
    // 選択したフローをコピーします
    copyFlow() {
      this.tmpChannelFlowNo++;
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === this.currentFlow.tradingFlowId) || (item.flowUuid && item.flowUuid === this.currentFlow.flowUuid);
      });

      const flowType = _.get(flow, 'tradingChannelInfo.flowType');

      let flows = [];
      // 二国間取引の場合flowTypeはnull
      if (!flowType) {
        // 二国間取引の場合
        // console.log('二国間');
        const copyFlow = this.createCopyFlow(flow);
        this.form.items.push(copyFlow);
        flows.push(copyFlow);
      } else {
        // 三国間取引の場合
        // console.log('三国間');
        flows = _.map(this.getPairFlows(flow), o => this.createCopyFlow(o));
        this.form.items = [...this.form.items, ...flows];
      }
      this.expandRowKeys.push(...flows.map(flow => flow.flowUuid));

      this.resetCurrentFlow();
      this.isDirty = true;
    },
    // Menuボタン切替
    onDropDownClick(command) {
      if (command === 'viewPattern') {
        this.toggleViewPattern();
      } else if (command === 'savePattern') {
        this.toggleSavePattern();
      } else if (command === 'setSection') {
        this.toggleSetSection();
      }
    },
    // View Pattern
    toggleViewPattern() {
      this.isShowViewPattern = !this.isShowViewPattern;
    },
    // Save Pattern
    toggleSavePattern() {
      this.isShowSavePattern = !this.isShowSavePattern;
    },
    // Set Section
    toggleSetSection() {
      this.isShowSetSection = !this.isShowSetSection;
    },
    assignSection(exporter, importer, forwarder, overWrite = false) {
      if (exporter) this.setSection(exporter, COMPANY_ROLE_CD.EXPORTER, overWrite);
      if (importer) this.setSection(importer, COMPANY_ROLE_CD.IMPORTER, overWrite);
      if (forwarder) this.setSection(forwarder, COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER, overWrite);
      this.toggleSetSection();
    },
    setSection(section, role, overWrite) {
      const targetFlow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === this.currentFlow.tradingFlowId) || (item.flowUuid && item.flowUuid === this.currentFlow.flowUuid);
      });
      let entityStatus = targetFlow.entityStatus;
      if (entityStatus) {
        const tmpProcessList = _.get(this.getTmpFlow(targetFlow), 'tmpProcessList', []);
        entityStatus = _.map(entityStatus, entity => {
          entity.processStatus = _.map(entity.processStatus, process => {
            const tmpProcess = _.find(tmpProcessList, ['tmpProcessId', process.processId]);
            if (tmpProcess && process.editableFlg === EDITABLE_FLG.ON) {
              if (Number(tmpProcess.tmpFromRoleId) === role) {
                if (overWrite || !process.fromSectionId) {
                  process = this.setProcessSection(process, section, 'from');
                }
              }
              if (Number(tmpProcess.tmpToRoleId) === role) {
                if (overWrite || !process.toSectionId) {
                  process = this.setProcessSection(process, section, 'to');
                }
              }
            }
            return process;
          })
          return entity;
        })
      }
    },
    setProcessSection(process, section, fromTo) {
      return {
        ...process,
        [`${fromTo}CustomerId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? section.tradingId : null,
        [`${fromTo}CompanyId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? null : section.tradingId,
        [`${fromTo}CompanyShortName`]: section.tradingShortName,
        [`${fromTo}CompanyName`]: section.tradingName,
        [`${fromTo}SectionId`]: section.sectionId,
        [`${fromTo}SectionName`]: section.sectionName,
        [`${fromTo}SectionShortName`]: section.sectionShortName,
        [`${fromTo}CompanyIcon`]: section.tradingIcon,
      }
    },
    getTmpFlow(flow) {
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');
      if (flowType) {
        return _.find(this.tmpTradingFlowList, {tmpFlowType: flowType}) || {};
      } else {
        return this.tmpTradingFlowList[0];
      }
    },
    // 取り引きチャネルが同じフローを輸出、輸入の順で返却します
    getPairFlows(flow) {
      let flows = [flow];
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');
      if (!flowType) {
        // 三国間取引じゃないときはそのまま返却
        return flows;
      }

      const tradingChannelNo = _.get(flow, 'tradingChannelInfo.tradingChannelNo');
      const channelFlowNo = _.get(flow, 'tradingChannelInfo.channelFlowNo');

      if (tradingChannelNo) {
        flows = _.filter(this.form.items, item => {
          return _.get(item, 'tradingChannelInfo.tradingChannelNo') === tradingChannelNo;
        });
      } else if (channelFlowNo) {
        flows = _.filter(this.form.items, item => {
          return _.get(item, 'tradingChannelInfo.channelFlowNo') === channelFlowNo;
        });
      }

      flows = _.sortBy(flows, o => {
        return _.get(o, 'tradingChannelInfo.flowType');
      });

      return flows;
    },
    // 別取引を別タブで開きます。
    showOtherTrading(row) {
      this.linkToOtherWindow({
        name: 'ManageTrading',
        params: { tradingId: row.otherTradingId },
      });
    },
    //フローの展開状態の更新
    updateExpand(expandRow){
      if (expandRow !== undefined){
        this.expandRowKeys = expandRow;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.chevron {
  display: block;
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  background: url(../assets/images/icons/chevron_down.svg) no-repeat center;
  background-size: 100% auto;
  cursor: pointer;
  transition: transform .3s;
  margin-left: 24px;

  &.open {
    transform: rotate(180deg);
  }
}

i.url_copy {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: url(../assets/images/icons/icon_copy.svg) no-repeat center;
  background-size: 20px auto;
  margin-left: 24px;
  cursor: pointer;
  &:hover {
    opacity: .5;
  }
}

.tw_container {
  padding-top: 43px;
  padding-bottom: 24px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tw_breadcrumbs {
  margin-bottom: 10px;
}

.buttons {
  flex-shrink: 0;
  padding-left: 20px;
}

.sub_buttons {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: right;
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

::v-deep .backdrop {
  z-index: 10000;
}

::v-deep .side_drawer {
  width: auto;
  width: 1076px;
  z-index: 10001;
  >.inner {
    padding: 20px 44px 48px;
  }
}

::v-deep .el-drawer {
  background: $color_gray_300;

  .el-drawer__header {
    padding: 25px 41px 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: $color_black;

    .el-drawer__close-btn {
      display: none;
    }

    i {
      color: #9191A4;
    }
  }
}
</style>
